<template>
  <div class="bac">
    <div class="main">
      <div class="first_line">
        <p>企业名称：</p>
        <input type="text" v-model="subForm.enName">

        <p>法定代表人：</p>
        <input v-model="subForm.representative">
      </div>
      <div class="second_line">

        <p>公司地址：</p>
        <input type="text" v-model="subForm.enAddress">

      </div>
      <div class="third_line">
        <p>统一社会信用代码：</p>
        <input type="text" v-model="subForm.creditCode">

        <p>经营范围：</p>
        <input type="text" style="height: 207px" v-model="subForm.businessScope">
      </div>
      <div class="fourth_line">

        <p>上传营业执照：</p>
        <el-upload :on-change="imgChange">
          <i>点击上传文件</i>
        </el-upload>
      </div>

      <button @click="submit">提交认证</button>
    </div>
  </div>
</template>

<script>
let formData = new FormData();
export default {
  name: "CorporateCertification",
  data(){
    return{
      subForm:{
        enName:'',
        enAddress:'',
        creditCode:'',
        representative:'',
        establishedTime:'',
        businessScope:'',
        businessLicense:'',
      }
    }
  },
  methods:{
    imgChange (files, fileList) {
      formData.append('businessLicense',fileList[0].raw)
      this.hideUpload = fileList.length >= this.limitNum;
      if (fileList) {
        this.$refs.uploadElement.clearValidate();
      }
    },
    submit() {
      //将数据转化为formData格式
      const time = new  Date().getTime()
      this.subForm.establishedTime = time.toString();
      formData.append('enName',this.subForm.enName);
      formData.append('enAddress',this.subForm.enAddress);
      formData.append('creditCode',this.subForm.creditCode);
      formData.append('representative',this.subForm.representative);
      formData.append('establishedTime',this.subForm.establishedTime);
      formData.append('businessScope',this.subForm.businessScope);


      this.$http({
        method: 'post',
        url: '/enterpriseAuthenticate',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        if (res.data.code === 0) {
          this.$notify({
            title: '上传失败',
            message: '',
            type: 'warning'
          });
        } else {
          this.$notify({
            title: '上传成功',
            message: '',
            type: 'success'
          })
        }
        this.$router.push('/CompanyBackHome')
      })
    }
  }
}
</script>

<style scoped>
.bac{
  /*float: left;*/
  width: 1920px;
  padding-left: 358px;
  padding-top: 50px;
  background-color: #f4f4f4;
}

.main{
  position: relative;
  width: 1462px;
  height: 720px;
  background-color: #ffffff;
  padding-top: 80px;
  padding-left: 120px;
}

.medium_head{
  width: 1458px;
  height: 50px;
  margin-left: 120px;
  margin-bottom: 60px;

}

.medium_head i{
  display: block;
  float: left;
  width: 2px;
  height: 48px;
  margin-right: 20px;
  background-color: #ec414d;
}

.medium_head p{
  float: left;
  width: 102px;
  margin-top: 10px;
  font-size: 20px;
  color: #5c5c5c;
}

.main p{
  float: left;
  font-size: 18px;
  color: #212121;
}

.main input{
  display: block;
  float: left;
  width: 425px;
  height: 35px;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
}

.main div{
  margin-bottom: 25px!important;
}

.main i{
  display: block;
  float: left;
  color: #ec414d;
  font-size: 20px;
}

.main .first_line{
  width: 1462px;
  height: 32px;
  margin-left: 117px;
  margin-bottom: 15px;
}

.main .first_line input{
  margin-right: 112px;
}


.main .second_line{
  width: 1462px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: 117px;
}

.main .second_line input{
  margin-right: 130px;
}

.main .second_line::before{
  content: "*";
  color: #ec414d;
}

.main .third_line{
  float: left;
  width: 1462px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: 45px;
  /*margin-left: -100px;*/
}

.main .third_line input{
  margin-right: 130px;
}

/*.main .fourth_line{*/
/*  width: 1462px;*/
/*  height: 32px;*/
/*  margin-left: 80px;*/
/*  margin-bottom: 15px;*/
/*}*/

.main .fourth_line{
  float: left;
  width: 518px;
  height: 152px;
  margin-bottom: 15px;
  margin-left: 80px;
}

.main .fourth_line div{
  float: left;
  margin-right: 99px;
  /*border: none;*/
  border: 1px dashed #959694;
}

.main .fourth_line div i{
  display: block;
  float: left;
  width: 220px;
  height: 152px;
  text-align: center;
  font-size: 16px;
  color: #979695;
  padding-top:68px;
  cursor: pointer;


}

.main .fifth_line{
  width: 1462px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: 90px;
}

.main .fifth_line input{
  margin-right: 89px;
}

.main .fifth_line input:nth-of-type(2){
  float: left;
  height: 110px;
}

.main .sixth_line{
  float: left;
  width: 600px;
  height: 35px;
  margin-bottom: 15px;
  margin-top :-36px;
  margin-left: 56px;
}

.main button{
  position: absolute;
  left: 635px;
  bottom: 86px;
  width: 290px;
  height: 78px;
  background-color: #ec414d;
  font-size: 24px;
  color: #ffffff;
  border-radius: 5px;
}

</style>